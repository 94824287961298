import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_LOGIN_API_USERNAME,
  password: process.env.VUE_APP_LOGIN_API_PASSWORD
};

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(`(?:^|; )${name}=([^;]+)`); // is `^name` or `; name`
    var value = re.exec(document.cookie);
    return (value != null) ? decodeURIComponent(value[1]) : null;
  })('csrftoken');
}

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const LOGIN_API_PATH = process.env.VUE_APP_LOGIN_API_PATH;

const loginAPI = {

  async signUp(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/signup/`), DOMAIN);
    const response = await axios.post(url, data);
    if (response.status === 201) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async signIn(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/signin/`), DOMAIN);
    const response = await axios.post(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async signOut(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/signout/`), DOMAIN);
    const response = await axios.get(url, data);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async validationRegistration(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/signup-confirmation/`), DOMAIN);
    const response = await axios.post(url, data);
    if (response.status === 200) {
      return true;
    } else {
      console.error(response);
      return false;
    }
  },

  async getOrganisationsList() {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/organisations/`), DOMAIN);
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async getUserDetail() {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/user/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async updateUserDetail(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/user/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(response);
      return false;
    }
  },

  async forgottenPasswordRequest(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/forgotten-password-request/`), DOMAIN);
    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err.response;
    }
  },

  async forgottenPasswordConfirm(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/forgotten-password-confirmation/`), DOMAIN);
    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err.response;
    }
  },

  async newEmailConfirm(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/email-update-confirmation/`), DOMAIN);
    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  },

  async getTermsOfUse() {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/term-of-use/`), DOMAIN);
    try {
      const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err.response;
    }
  },

  async postTermsOfUseAgreement(data) {
    const url = new URL(path.join(`${i18n.locale}${LOGIN_API_PATH}`, `/term-of-use/`), DOMAIN);
    try {
      const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
      if (response.status === 200) {
        return response;
      }
    } catch (err) {
      return err.response;
    }
  }

};

export default loginAPI;
